import styled from 'styled-components';

export const Container = styled.div`
  width: 920px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: #222;
  max-width: 100%;
  height: 100%;
  min-height:400px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 30px;
  }
`;
export const Subcontainer = styled.div`
  display: block;
  font-size: 14px;
  direction: ltr;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  font-size: 14px;
  line-height: 1;
  color: #222;
  position: relative;
  -webkit-font-smoothing: antialiased;
`;

export const SubHeader = styled.p`
  font-style: italic;
  line-height: inherit;
  font-weight: bold;
  color: #000000;
  font-size: 1.1em;
  direction: ltr;
  width: 71%;
  text-align: left;
  margin-bottom: 17px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const Header1 = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  margin-top: 0;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin: 20px 0;
  padding: 0;
  direction: ltr;
`;
