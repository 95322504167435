import React from 'react';
import * as Styled from './troubleWithKioskTemplateStyle';
import { troubleWithKioskData } from '../../utils/constant';
import HeaderWithParagraphList from '../../components/headerWithParagraphList/headerWithParagraphList';
import BackButton from '../../components/helpBackButton/helpBackButton';

const TroubleWithKioskTemplate = () => {
  return (
    <div>
      <Styled.Container>
        <Styled.Header1>TROUBLE WITH THE KIOSK?</Styled.Header1>
        <Styled.Subcontainer>
          <Styled.SubHeader>
            {' '}
            For any of the following kiosk issues, please call 1-833-820-6080
            and a member of our customer service team will gladly assist you.
          </Styled.SubHeader>
          <HeaderWithParagraphList
            unOrderedList={troubleWithKioskData.unOrderedList}
          />
        </Styled.Subcontainer>
        <BackButton />
      </Styled.Container>
    </div>
  );
};

export default TroubleWithKioskTemplate;
